<template>
  <div class="my-6">
    <div class="mb-6 overflow-hidden">
      <ul style="list-style-type: none">
        <li v-for="address in addresses" :key="address.id">
          <div class="uk-width-1-1">
            <label
              ><input
                class="uk-radio"
                type="radio"
                name="shipping-addresses"
                checked
                v-model="multipleShippingAddresses"
                :value="false"
              />
              <b> {{ address.first_name }} {{ address.last_name }} </b><br />
              <b>Dirección: </b> {{ address.address }}<br />
              <b>Teléfono: </b> {{ address.phone }}<br /><br />
            </label>
          </div>
        </li>
      </ul>

      <div
        v-for="(userQuotable, index) in userQuotables"
        :key="userQuotable.id"
        class="flex flex-col py-4 md:flex-row md:py-0 items-center justify-self-start overflow-hidden mb-4 bg-white rounded-xl shadow border border-gray-200"
        :class="{
          'bg-red-100':
            userQuotable.quotable.current_stock < userQuotable.quantity,
        }"
      >
        <div class="flex w-1/4 h-full justify-left items-center">
          <div>
            <router-link
              v-if="userQuotable.quotable.media.length"
              :to="
                '/store/' +
                (userQuotable.quotable.redeemable_type ===
                'App\\Models\\Product'
                  ? userQuotable.quotable.redeemable.id
                  : userQuotable.quotable.redeemable.product.id)
              "
            >
              <div class="flex items-center bg-gray-900">
                <div class="p-2 h-full">
                  <span class="text-sm font-bold text-white">{{ index + 1 }}</span>
                </div>
                <img
                  :src="userQuotable.quotable.media[0].url"
                  alt=""
                  class="w-36 h-full object-cover"
                />
              </div>
            </router-link>
          </div>

          <div
            class="top-0 mx-4 pr-2 font-bold flex items-center justify-center relative rounded-full w-24 h-6"
            :style="storefrontBackground"
          >
            <div>
              <img v-if="pointsIcon" :src="pointsIcon" alt="" width="50px" />
            </div>
            <div class="text-white text-sm">
              {{ formatThousands(userQuotable.quotable.current_price) }}
            </div>
          </div>
        </div>

        <div class="flex-1 flex flex-col items-center m-4">
          <div>
            <router-link
              class="text-lg font-bold text-gray-700 hover:text-gray-900"
              :to="
                '/store/' +
                (userQuotable.quotable.redeemable_type ===
                'App\\Models\\Product'
                  ? userQuotable.quotable.redeemable.id
                  : userQuotable.quotable.redeemable.product.id)
              "
            >
              {{
                userQuotable.quotable.redeemable_type === "App\\Models\\Product"
                  ? userQuotable.quotable.display_name
                  : userQuotable.quotable.redeemable.product
                      .storefront_redeemables[0].display_name +
                    " - " +
                    userQuotable.quotable.redeemable.value
              }}
            </router-link>
          </div>
          <div class="text-xs text-center text-gray-600">
            <strong>Agregado el: </strong> <br />{{
              formatDatetime(userQuotable.created_at)
            }}
          </div>

          <div
            class="w-36 text-center bg-green-400 font-bold text-white text-xs px-4 py-2 rounded-full"
            v-if="userQuotable.quotable.current_stock > userQuotable.quantity"
          >
            (Stock:
            {{ formatThousands(userQuotable.quotable.current_stock) }}
            )
          </div>

          <div
            v-if="userQuotable.quotable.current_stock == userQuotable.quantity"
            class="w-32 text-center bg-orange-400 text-white text-xs font-bold px-4 py-2 rounded-full"
          >
            (Stock:
            {{ formatThousands(userQuotable.quotable.current_stock) }}
            )
          </div>
          <div
            v-if="userQuotable.quotable.current_stock < userQuotable.quantity"
            class=" w-32 text-center bg-red-500 text-white text-xs font-bold px-4 py-2 rounded-full"
          >
            (Stock:
            {{ formatThousands(userQuotable.quotable.current_stock) }}
            )
          </div>
        </div>

        <div class="w-1/4 text-center" v-if="editable">
          <div class="mb-2">
            <div>
              <div>
                <label for="cantidad" class="text-gray-700 text-sm font-bold"
                  >Cantidad</label
                >
              </div>
              <div class="">
                <div>
                  <input
                    type="number"
                    class="bg-gray-100 p-2 rounded-full w-24 text-center shadow-inner"
                    v-model="userQuotable.quantity"
                    min="1"
                    :max="userQuotable.quotable.current_stock"
                    v-on:change="
                      updateQuotable(userQuotable, $event.target.value)
                    "
                    @keydown.prevent
                  />
                </div>
                <div
                  v-if="
                    userQuotable.quantity > userQuotable.quotable.current_stock
                  "
                >
                  <span class="text-xs font-bold text-red-500 m-0"
                    >No hay suficiente stock
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-center">
            <div class="mr-2">
              <button
                v-if="userQuotable.saved_for_later === 0"
                class="bg-blue-500 text-white p-2 rounded-full flex items-center hover:bg-blue-800"
                @click="saveForLater(userQuotable.id)"
                title="Guardar para después"
              >
                <div uk-icon="future" class="icon"></div>
              </button>
              <button
                v-else
                class="bg-green-500 text-white p-2 rounded-full flex items-center"
                @click="moveToCart(userQuotable.id)"
                title="Mover a carrito"
              >
                <div uk-icon="cart" class="icon"></div>
              </button>
            </div>

            <div class="">
              <button
                class="bg-gray-400 text-white p-2 rounded-full flex items-center hover:bg-red-500"
                :href="'#delete-userQuotable-modal-' + tableId"
                title="Eliminar"
                uk-toggle
                @click="setEditMode(userQuotable)"
              >
                <div uk-icon="trash" class="icon flex items-center"></div>
              </button>
            </div>
          </div>
        </div>

        <div class="w-48 flex justify-left items-center mx-6">
          <div>
            <img v-if="pointsIcon" :src="pointsIcon" alt="" width="50px" />
          </div>
          <div>
            {{
              formatThousands(
                userQuotable.quantity * userQuotable.quotable.current_price
              )
            }}
            {{ pointsName }}
          </div>
        </div>
      </div>
    </div>

    <div
      :id="'edit-userQuotable-modal-' + tableId"
      class="uk-flex-top"
      uk-modal="bg-close: false"
    >
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          <span v-if="mode === 'new'">Crear</span>
          <span v-else>Editar</span>
          producto
        </div>
      </div>
    </div>

    <div
      :id="'delete-userQuotable-modal-' + tableId"
      class="uk-flex-top"
      uk-modal="bg-close: false"
    >
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical uk-border-rounded uk-overflow-hidden"
        v-if="selectedUserQuotable"
      >
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-text-large">
          ¿Está seguro que desea eliminar el producto
          {{
            selectedUserQuotable.quotable_type === "App\\Models\\Product"
              ? selectedUserQuotable.quotable.name
              : selectedUserQuotable.quotable_type ===
                "App\\Models\\ProductVariation"
              ? selectedUserQuotable.quotable.product.name
              : ""
          }}?
        </div>

        <div class="uk-text-center uk-margin-top">
          <button
            class="uk-button uk-button-danger uk-border-rounded"
            @click="removeQuotable(selectedUserQuotable.id)"
            :disabled="isLoading || isEditing"
          >
            <span v-if="isLoading || isEditing" uk-spinner></span>
            <span v-else>Eliminar</span>
          </button>
        </div>
      </div>
    </div>

    <div class="flex justify-center">
      <div>
        <router-link
          tag="button"
          :disabled="canGotoCheckout"
          to="/checkout"
          class="uk-button uk-button-primary uk-border-rounded"
        >
          Ir a checkout
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UIkit from "uikit";

const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

export default {
  name: "UserQuotablesRedeemTable",

  props: ["userQuotables", "editable", "isLoading", "addresses"],

  data() {
    return {
      tableId: uuidv4(),
      model: {
        quantity: null,
      },
      selectedUserQuotable: null,
      mode: "edit",
      isEditing: false,
    };
  },

  computed: {
    ...mapGetters([
      "storefrontStorefront",
      "storefrontBackground",
      "pointsName",
      "pointsIcon",
      "pointsBalance",
    ]),

    canGotoCheckout() {
      let hasOutOfStock = this.userQuotables.some(
        (userQuotable) =>
          userQuotable.quantity > userQuotable.quotable.current_stock
      );

      return this.userQuotables.length > 0 && hasOutOfStock;
    },
  },

  mounted() {
    this.userQuotables.map((quotable) => {
      if (quotable.quantity < 0) {
        quotable.quantity = 0;
      }

      if (quotable.quantity > quotable.quotable.current_stock) {
        quotable.outOfStock = true;
      }
    });
  },

  methods: {
    saveForLater(userQuotableId) {
      this.isEditing = true;
      this.axios
        .put("/quotes/product/" + userQuotableId + "/save-for-later")
        .then(() => {
          UIkit.notification({
            message: '<span uk-icon="icon: check"></span> Producto guardado.',
            pos: "bottom-left",
          });
          this.$router.go();
        })
        .finally(() => {
          this.isEditing = false;
        });
    },
    moveToCart(userQuotableId) {
      this.isEditing = true;
      this.axios
        .put("/quotes/product/" + userQuotableId + "/move-to-cart")
        .then(() => {
          UIkit.notification({
            message: '<span uk-icon="icon: check"></span> Producto en carrito.',
            pos: "bottom-left",
          });
          this.$router.go();
        })
        .finally(() => {
          this.isEditing = false;
        });
    },
    setEditMode(userQuotable) {
      this.mode = "edit";
      this.selectedUserQuotable = userQuotable;
      this.model.quantity = userQuotable.quantity;
    },

    updateQuotable(userQuotable) {
      if (userQuotable.quantity < 0) {
        userQuotable.quantity = 0;
      }

      console.log(userQuotable);
      this.isEditing = true;
      this.axios
        .put("/quotes/product/" + userQuotable.id, userQuotable)
        .then(() => {})
        .finally(() => {
          this.isEditing = false;
        });
    },
    removeQuotable(userQuotableId) {
      this.isEditing = true;
      this.axios
        .delete("/quotes/product/" + userQuotableId)
        .then(() => {
          UIkit.notification({
            message:
              '<span uk-icon="icon: check"></span> Producto removido de tu cotización.',
            pos: "bottom-left",
          });
          this.$router.go();
          UIkit.modal("#delete-userQuotable-modal-" + this.tableId).hide();
        })
        .finally(() => {
          this.isEditing = false;
        });
    },
  },
};
</script>

<style scoped>
.icon {
  min-width: 20px;
}
</style>
